<template>
  <div>
    <div class="bg-white padd17">
      <content-title :nav="nav"></content-title>
      <div class="UserFacts">
        <div class="Facts">
          <img class="img" src="../../assets/yh1.png" alt="" />
          <div class="div-p">
            <p class="p-1">{{ userCount.total || 0 }}</p>
            <p class="p-2">用户总数</p>
          </div>
        </div>
        <div class="Facts">
          <img class="img" src="../../assets/yh2.png" alt="" />
          <div class="div-p">
            <p class="p-1">{{ userCount.dayCount || 0 }}</p>
            <p class="p-2">今日新增</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom20">  </div> -->

    <div class="container">
      <el-row>
        <el-tabs v-model="activeTableName" @tab-click="listTab">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="登录账号">
              <el-input v-model="login_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="nick_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="注册时间">
              <el-date-picker v-model="date" type="daterange" align="right" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="账户状态">
              <el-select v-model="status" placeholder="请选择" style="width: 100px" @change="getUserList" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="注册平台">
              <el-select v-model="register_platform" placeholder="请选择"  clearable>
                <el-option v-for="item in registerPlatform" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button @click="search()" type="primary">查询</el-button>
            </el-form-item>
          </el-form>

          <el-table :data="user_list" :fit="true" :cell-style="{ 'text-align': 'center' }" v-loading="is_loading"
            :header-cell-style="{ 'text-align': 'center' }" style="overflow: scroll-x; width: 100%">
            <el-table-column label="用户ID" prop="user_id"></el-table-column>
            <el-table-column label="用户昵称" prop="nick_name">
            </el-table-column>
            <el-table-column label="登录账号" prop="login_name" width="150">
            </el-table-column>
            <!-- <el-table-column label="领券状态" prop=""> </el-table-column> -->
            <el-table-column label="登录时间" width="200" prop="login_time">
            </el-table-column>
            <el-table-column label="注册时间" prop="create_time" width="200"></el-table-column>
            <el-table-column label="所属客服" prop="">
              <template v-slot="{row}">
                <div v-if="row.map && row.map.consultant && row.map.consultant.length>0">
                  <link-to :to="{name:'consultantDetails',params:{id: row.map.consultant[0].admin_id}}">  {{ row.map.consultant[0].admin_name }}</link-to>
                </div>
                <el-button v-else type="text" @click="openAddConsultant(row)">分配客服</el-button>
              </template>

            </el-table-column>
            <!-- <el-table-column label="所属区域" prop=""></el-table-column> -->
            <el-table-column label="状态" prop="">
              <template slot-scope="{row}">
                <el-tag type="success" v-if="row.status == 1">正常</el-tag>
                <el-tag type="info" v-if="row.status == 2">限制登录</el-tag>
                <el-tag type="danger" v-if="row.status == 3">冻结</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="" width="200">
              <template slot-scope="scope">
                <p style="margin-bottom: 5px; color: #409eff; font-size: 14px">
                  <samp style="margin-right: 20px" @click="toDetails(scope.row)">查看详情</samp>
                  <samp @click="update(scope.row, 3)" v-if="scope.row.status == 1">冻结</samp>
                  <samp @click="update(scope.row, 1)" v-if="scope.row.status != 1">解冻</samp>
                </p>
                <p style="color: #409eff; font-size: 14px">
                  <samp :style="'margin-right: 20px;color: #F56C6C'" @click="update(scope.row, 2)">限制登录</samp><samp
                    @click="update(scope.row, 5)">注销</samp>
                </p>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination :total="user_total" :current-page="currentPage" @current-change="payPageChange">
          </el-pagination>
        </el-tabs>
      </el-row>
    </div>

    <el-dialog
  title="提示"
  :visible.sync="consultantShow"
  width="30%">
  
  <el-form>
    <el-form-item label="用户">
   <div>{{ curUser.nick_name }}</div>
  </el-form-item>
  <el-form-item label="顾问">
   <admin-select @change="adminChange"></admin-select>
  </el-form-item>

</el-form>

  <span slot="footer" class="dialog-footer">
    <el-button @click="consultantShow = false">取 消</el-button>
    <el-button type="primary" @click="addConsultant">确 定</el-button>
  </span>
</el-dialog>


  </div>
</template>

<script>
import { uncodeUtf16 } from '@/js/utils.js'
import adminSelect from "@/components/select/adminSelect.vue"
export default {
  components:{
    adminSelect
  },
  data() {
    return {
      nav: { firstNav: "用户管理中心", secondNav: "注册用户列表" },
      activeTableName: "first",
      currentPage: 1,
      is_loading: true,
      login_name: null,
      mobile: null,
      nick_name: "",
      user_list: [],
      user_total: 0,
      userCount: {},
      date: "",
      status: 1,
      options: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "限制登录",
        },
        {
          value: 3,
          label: "冻结",
        },
      ],
      consultantShow:false,
      curUser:{},
      admin:{},
      manager:JSON.parse(localStorage.getItem("managerInfo")),
      register_platform:'',
       registerPlatform:[{
          value:'sport',
          name:'海马运动go小程序'
      },{
          value:'sport_coach_wx',
          name:'海马教练局小程序'
      },{
          value:'dy_sport',
          name:'海马运动抖音小程序'
      },{
          value:'android',
          name:'海马运动android_APP'
      },{
          value:'ios',
          name:'海马运动ios_APP'
      },{
          value:'android_coach',
          name:'海马教练局android_APP'
      },{
          value:'ios_coach',
          name:'海马教练局ios_APP'
      }],
    };
  },
  methods: {
    //顾问
    openAddConsultant(row){
      this.curUser = row
      this.consultantShow = true
    },
    adminChange(v){
      this.admin = v
    },
    addConsultant(){
      let url = "user/consultantRelative/insert";

let data = {
  admin_id:this.admin.id,
  admin_name:this.admin.name,
  user_id:this.curUser.user_id,
  user_name:this.curUser.nick_name,
  add_admin_id:this.manager.id,
  add_admin_name:this.manager.name,
}

this.$axios.post(url, data)
  .then((res) => {
    this.$message("分配顾问成功")
    this.admin={}
    this.consultantShow = false
    this.getUserList()
  });
    },
    uncodeUtf16(str) {
      return uncodeUtf16(str)
    },
    //注册用户统计
    user_count() {
      let url = "user/user/queryUserRegisterCountByType";

      let params = {
      }

      this.$axios
        .get(url, { params })
        .then((res) => {
          this.userCount = res.data.data
        });
    },
    toDetails(row) {
      this.$router.push({
        name: "userDetails",
        params: {
          id: row.user_id,
        },
      });
    },
    getUserList() {
      this.is_loading = true;
      let url = "/user/user/queryByMessage";
      let data = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.status,
        login_name: this.login_name || null,
        nick_name: this.nick_name || null,
        register_platform: this.register_platform || null
      };
      if (this.date) {
        data.start_time = this.date[0] + ' 00:00:00'
        data.end_time = this.date[1] + ' 24:00:00'
      }
      this.$axios
        .get(url, {
          params: {
            ...data,
          },
        })
        .then((res) => {
          this.is_loading = false;
          this.user_list = res.data.data.rows.filter((item) => {
            return item.status != 5;
          });
          this.user_total = res.data.data.total;

        });
    },
    search() {
      this.currentPage = 1;
      this.getUserList();
    },

    shelve(row) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    update(row, status) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },

    payPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },

    listTab(tab, event) { },
  },
  mounted() {
    // this.user_search()
    this.user_count()
    this.getUserList();
  },
};
</script>

<style>
.payment.el-row .el-col {
  display: flex;
  height: 10rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.07);
  padding: 1rem 2.5rem;
}

.payment.el-row .el-col div {
  margin-right: 3rem;
}

.el-row.payment {
  margin-bottom: 3rem;
}

.el-row.payment .el-col div div:first-child {
  font-size: 3rem;
}

.el-row.payment .el-col div.used div:first-child {
  color: #4fc5ea;
}

.el-row.payment .el-col div.unused div:first-child {
  color: #f44c4c;
}

.el-row.payment .el-col div.total div:first-child {
  color: #6c6fbf;
}

.payment .used,
.payment .unused,
.payment .total {
  display: flex;
  height: inherit;
  flex-direction: column;
  align-content: center;
}

.main .el-input {
  width: inherit;
}

.el-button {
  margin-bottom: 10px;
}
</style>
